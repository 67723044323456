<template>

    <div class="main-content">
        <div v-if="!isLoggedIn" class="basic-page">
            <router-view :key="$route.fullPath" />
        </div>

        <div v-if="isLoggedIn" class="page">

            <NavMenu />

            <div class="main">

                <div class="top-row px-4" :style="secondaryBackground" style="border-bottom-width:1px; border-bottom-color:gray;">
                    <div id="divTopTitleBar" v-if="currentCollapseState" class="container-fluid float-left">
                        <img src="../assets/CryptoTaxSpace_Logo.png" width="110" alt="logo" />
                        <span class="ml-2" style="font-size:x-small;">{{currentAppVersion}}</span>
                    </div>

                    <span v-if="hasSubscription" class="container-fluid float-left" style="color:var(--var-theme-color) !important; font-weight:bold;">Premium Member</span>
                    <span v-if="!hasSubscription" class="container-fluid float-left" style="color:var(--var-theme-color) !important; font-weight:bold;">Trial Account</span>

                    <div>
                        <b-dropdown id="dropdown-right" right style="min-width:100px; color:white;" variant="primary">
                            <template #button-content>
                                <font-awesome-icon :icon="['fas', 'user']" />
                                <span class="ml-2" style="color:white;"> {{loggedInUser}}</span>
                            </template>
                            <b-dropdown-item variant="dark" href="#" @click="logout()">
                                <font-awesome-icon :icon="['fas', 'sign-out-alt']" /> &nbsp;
                                Sign Out
                            </b-dropdown-item>
                            <b-dropdown-divider style="border-top: 1px solid gray; height:5px;"></b-dropdown-divider>
                            <b-dropdown-item variant="primary" href="#" @click="showChangePasswordDialog()">
                                <font-awesome-icon :icon="['fas', 'key']" /> &nbsp;
                                Change Password
                                <ChangePasswordForm ref="changePasswordForm" />
                            </b-dropdown-item>
                            <b-dropdown-divider style="border-top: 1px solid gray; height:5px;"></b-dropdown-divider>
                            <b-dropdown-item variant="success" href="#" @click="showReceiptsDialog">
                                <font-awesome-icon :icon="['fas', 'receipt']" /> &nbsp;
                                My Orders
                                <ReceiptsForm ref="receiptsForm" />
                            </b-dropdown-item>
                            <b-dropdown-divider style="border-top: 1px solid gray; height:5px;"></b-dropdown-divider>
                            <b-dropdown-item variant="danger" href="#" @click="openClearDialog">
                                <font-awesome-icon :icon="['fas', 'trash']" /> &nbsp;
                                Clear My Data
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>

                <div class="content px-4">
                    <router-view :key="$route.fullPath" />
                </div>

            </div>

        </div>

        <!-- CONFIRM CLEAR DIALOG -->
        <v-dialog v-model="showClearDialog" max-width="500" persistent dark>  
            <v-card>
                <v-card-title class="text-h5 red--text">
                    Confirm: Clear All Tax Data
                </v-card-title>

                <v-card-text class="white--text">
                    Are you sure you wish to continue? This will delete all uploaded and entered tax data from your browser.
                    <input autofocus style="visibility:hidden; width:1px;"> <!--Hack to prevent buttons from being auto-focused-->
                </v-card-text>

                <v-divider></v-divider>

                <v-btn color="primary"
                       text
                       @click="showClearDialog = false">
                    Cancel
                </v-btn>

                <div class="float-right">
                    <v-spacer></v-spacer>
                    <div>
                        <v-btn color="red"
                               text
                               @click="clearUserData">
                            Delete
                        </v-btn>

                    </div>
                </div>

            </v-card>
        </v-dialog>

    </div>

</template>

<script>

    import router from '../router';
    import NavMenu from '../components/nav-menu';
    import { appService } from '../services/app-service';
    import { serviceHelper } from '../shared/service-helper';
    import { mapGetters, mapActions } from 'vuex';
    const ChangePasswordForm = () => import('@/components/change-password-form');
    const ReceiptsForm = () => import('@/components/receipts-form');

    export default {
        components: { NavMenu, ChangePasswordForm, ReceiptsForm },
        name: 'MainContent',

        /* COMPONENT DATA  */
        data() {
            return {
                showClearDialog: false,
                secondaryBackground: {
                    backgroundImage: `url(${require('@/assets/secondarySpace.jpg')})`,
                    backgroundRepeat: 'repeat',
                },
            };
        },

        /* LIFE CYCLE METHODS  */
        async mounted() {

            var myAppVersion = this.currentAppVersion;
            var serverVersion = await appService.getAppVersion();
            if (myAppVersion !== serverVersion) {
                this.setAppVersionAction(serverVersion);

                //Not a first time user, logout/reload
                if (myAppVersion !== null && myAppVersion !== undefined) {
                    //Give enough time for version to update
                    await serviceHelper.simulateAwait(true, 200);

                    //Log user out first if logged in
                    if (this.isLoggedIn) {
                        await this.logoutUserAction();
                        window.location.reload(true);
                    }
                    else {
                        window.location.reload(true);
                    }
                }         
            }
        },

        /* WATCHERS */
        watch: {
            isLoggedIn: async function (val, oldVal) {
                if (val === false) {
                    router.push({ name: 'login', params: { SessionExpired: true } }).catch(err => { });
                }
            }
        },

        /* COMPONENT METHODS  */
        methods: {

            ...mapActions(['logoutUserAction', 'clearTaxInfoAction', 'setAppVersionAction']),

            openClearDialog() {
                this.showClearDialog = true;
            },

            async logout() {
                await this.logoutUserAction();
                router.push({ name: 'home'}).catch(err => { });
            },

            async clearUserData() {
                await this.clearTaxInfoAction();
                //Refresh the current page
                window.location.reload(true);
            },

            showChangePasswordDialog() {
                this.$refs.changePasswordForm.ShowModal();
            },

            showReceiptsDialog() {
                this.$refs.receiptsForm.ShowModal();
            }
        },

        /* COMPUTED PROPERTIES */
        computed: {
            ...mapGetters(['isLoggedIn', 'loggedInUser', 'currentAppVersion', 'currentCollapseState']),
            activePage() { return this.$route.name },
            hasSubscription() { return this.$store.getters.hasRequiredRole('Premium User'); },
        },
    };
</script>

<style scoped>

    /* Fix padding issue that happened once I added Vuetify with Bootstrap  */
    /deep/ .dropdown .show {
        padding-left: 0px !important;
    }

    ul {
        padding-left: 0px !important;
        list-style-type: none !important;
    }

    .page {
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .main {
        flex: 1;
    }

    .top-row {
        background-color: #323548;
        border-bottom: 1px solid gray;
        justify-content: flex-end;
        height: 3.5rem;
        display: flex;
        align-items: center;
    }

        .top-row ::deep a, .top-row .btn-link {
            white-space: nowrap;
            margin-left: 1.5rem;
        }

        .top-row a:first-child {
            overflow: hidden;
            text-overflow: ellipsis;
        }

    /* Mobile */
    @media (max-width: 740.98px) {

        .basic-page {
            margin: 10px;
        }

        .top-row:not(.auth) {
            /* display: none; */
        }

        .top-row.auth {
            justify-content: space-between;
        }

        .top-row a, .top-row .btn-link {
            margin-left: 0;
        }

        /* Hide in mobile*/
        #divTopTitleBar {
            display: none;
        }

    }

    /* Desktop */
    @media (min-width: 741px) {
        .page {
            flex-direction: row;
        }

        .top-row {
            position: sticky;
            top: 0;
            z-index: 99;
        }

        .main > div {
            padding-left: 2rem !important;
            padding-right: 1.5rem !important;
        }

        /* Show in desktop */
        #divTopTitleBar {
            display: inline-block;
        }
    }

</style>