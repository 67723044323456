<template>
    <div v-bind:class="sidebarCssClass" >

        <div class="navbar-default" :style="secondaryBackground" >

            <!--Mobile resolution-->
            <div id="divMobile">

                <div class="top-row pl-4 navbar navbar-dark" >
                    <a class="navbar-brand" href="" style="padding-top:0px;">
                        <img src="../assets/CryptoTaxSpace_Logo.png" width="100" alt="logo" />
                        <span class="ml-2" style="font-size:x-small; color:white;">{{currentAppVersion}}</span>
                    </a>

                    <button class="navbar-toggler" @click="toggleNavMenu()" style="margin-bottom: 5px;">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                </div>

                <div v-bind:class="navMenuCssClass" style="border-right-width:1px; border-right-color:gray; border-right-style:solid;">
                    <ul class="nav flex-column pl-0">
                        <li class="nav-item px-2">
                            <b-button title="Home" class="nav-link btn-default" v-bind:class="[{active: activeTab == 'home'}, sidebarButtonClass]" href="#" onclick="this.blur();" @click="linkTo('home', 'home')">
                                <span><font-awesome-icon :icon="['fa', 'home']" class="fa-fw" color="white" /></span>
                                <span class="navMenuText" v-bind:class="navMenuCssTextClass">Home</span>
                            </b-button>
                        </li>
                        <li class="nav-item px-2">
                            <b-button title="About" class="nav-link btn-default" v-bind:class="[{active: activeTab == 'about'}, sidebarButtonClass]" href="#" onclick="this.blur();" @click="linkTo('home', 'about')">
                                <span><font-awesome-icon :icon="['fa', 'info']" class="fa-fw" color="white" /></span>
                                <span class="navMenuText" v-bind:class="navMenuCssTextClass">About</span>
                            </b-button>
                        </li>
                        <li class="nav-item px-2">
                            <b-button title="Prices" class="nav-link btn-default" v-bind:class="[{active: activeTab == 'pricing'}, sidebarButtonClass]" href="#" onclick="this.blur();" @click="linkTo('home', 'pricing')">
                                <span><font-awesome-icon :icon="['fa', 'dollar-sign']" class="fa-fw" color="white" /></span>
                                <span class="navMenuText" v-bind:class="navMenuCssTextClass">Prices</span>
                            </b-button>
                        </li>
                        <li class="nav-item px-2">
                            <b-button title="FAQ" class="nav-link btn-default" v-bind:class="[{active: activeTab == 'faq'}, sidebarButtonClass]" href="#" onclick="this.blur();" @click="linkTo('home', 'faq')">
                                <span><font-awesome-icon :icon="['fa', 'question-circle']" class="fa-fw" color="white" /></span>
                                <span class="navMenuText" v-bind:class="navMenuCssTextClass">FAQ</span>
                            </b-button>
                        </li>
                        <li class="nav-item px-2">
                            <b-button title="Contact" class="nav-link btn-default" v-bind:class="[{active: activeTab == 'contact'}, sidebarButtonClass]" href="#" onclick="this.blur();" @click="linkTo('home', 'contact')">
                                <span><font-awesome-icon :icon="['fa', 'address-card']" class="fa-fw" color="white" /></span>
                                <span class="navMenuText" v-bind:class="navMenuCssTextClass">Contact</span>
                            </b-button>
                        </li>
                        <li class="nav-item px-2">
                            <b-button title="My Taxes" class="nav-link btn-default" v-bind:class="[{active: activeTab == 'mytaxes'}, sidebarButtonClass]" href="#" onclick="this.blur();" @click="linkTo('mytaxes', 'mytaxes')">
                                <span><font-awesome-icon :icon="['fas', 'file-invoice-dollar']" class="fa-fw" color="white" /></span>
                                <span class="navMenuText" v-bind:class="navMenuCssTextClass">My Taxes</span>
                            </b-button>
                        </li>
                    </ul>
                </div>
            </div>

            <!--Desktop resolution-->
            <div id="divDesktop">

                <div class="top-row navbar navbar-dark top-NavBarRow" v-bind:class="desktopPaddingClass">

                    <table>
                        <tr>
                            <td>
                                <a class="navbar-brand" v-bind:class="navMenuCssTextClass" href=""  >
                                    <img src="../assets/CryptoTaxSpace_Logo.png" width="110" alt="logo"/>
                                    <span class="ml-2" style="font-size:x-small; color:white;">{{currentAppVersion}}</span>
                                </a>
                            </td>
                            <td>
                                <button class="navbar-toggler sidebar-minimize" @click="minimizeSidebar()">
                                    <span class="navbar-toggler-icon"></span>
                                </button>
                            </td>
                        </tr>           
                    </table>
  
                </div>

                <div v-bind:class="sidebarCssClass">

                    <ul class="nav flex-column pl-0">
                        <li class="nav-item px-2">
                            <b-button title="Home" class="nav-link btn-default" v-bind:class="[{active: activeTab == 'home'}, sidebarButtonClass]" href="#" onclick="this.blur();" @click="linkTo('home', 'home')">
                                <span><font-awesome-icon :icon="['fa', 'home']" class="fa-fw" color="white" /></span>
                                <span class="navMenuText" v-bind:class="navMenuCssTextClass">Home</span>
                            </b-button>
                        </li>
                        <li class="nav-item px-2">
                            <b-button title="About" class="nav-link btn-default" v-bind:class="[{active: activeTab == 'about'}, sidebarButtonClass]" href="#" onclick="this.blur();" @click="linkTo('home', 'about')">
                                <span><font-awesome-icon :icon="['fa', 'info']" class="fa-fw" color="white" /></span>
                                <span class="navMenuText" v-bind:class="navMenuCssTextClass">About</span>
                            </b-button>
                        </li>
                        <li class="nav-item px-2">
                            <b-button title="Prices" class="nav-link btn-default" v-bind:class="[{active: activeTab == 'pricing'}, sidebarButtonClass]" href="#" onclick="this.blur();" @click="linkTo('home', 'pricing')">
                                <span><font-awesome-icon :icon="['fa', 'dollar-sign']" class="fa-fw" color="white" /></span>
                                <span class="navMenuText" v-bind:class="navMenuCssTextClass">Prices</span>
                            </b-button>
                        </li>
                        <li class="nav-item px-2">
                            <b-button title="FAQ" class="nav-link btn-default" v-bind:class="[{active: activeTab == 'faq'}, sidebarButtonClass]" href="#" onclick="this.blur();" @click="linkTo('home', 'faq')">
                                <span><font-awesome-icon :icon="['fa', 'question-circle']" class="fa-fw" color="white" /></span>
                                <span class="navMenuText" v-bind:class="navMenuCssTextClass">FAQ</span>
                            </b-button>
                        </li>
                        <li class="nav-item px-2">
                            <b-button title="Contact" class="nav-link btn-default" v-bind:class="[{active: activeTab == 'contact'}, sidebarButtonClass]" href="#" onclick="this.blur();" @click="linkTo('home', 'contact')">
                                <span><font-awesome-icon :icon="['fa', 'address-card']" class="fa-fw" color="white" /></span>
                                <span class="navMenuText" v-bind:class="navMenuCssTextClass">Contact</span>
                            </b-button>
                        </li>
                        <li class="nav-item px-2">
                            <b-button title="My Taxes" class="nav-link btn-default" v-bind:class="[{active: activeTab == 'mytaxes'}, sidebarButtonClass]" href="#" onclick="this.blur();" @click="linkTo('mytaxes', 'mytaxes')">
                                <span><font-awesome-icon :icon="['fas', 'file-invoice-dollar']" class="fa-fw" color="white" /></span>
                                <span class="navMenuText" v-bind:class="navMenuCssTextClass">My Taxes</span>
                            </b-button>
                        </li>
                    </ul>
                </div>
            </div>

            </div>
        </div>
</template>

<script>

    import { mapGetters, mapActions } from 'vuex';

    export default {

        name: 'NavMenu',

        /* COMPONENT DATA  */
        data() {
            return {
                minimizeSideBar: false,
                collapseNavMenu: false,
                secondaryBackground: {
                    boxSizing: 'border-box !important'
                    //backgroundColor: "black"            
                    //backgroundImage: `url(${require('@/assets/secondarySpace.jpg')})`,
                    //backgroundRepeat: 'repeat'
                },
            };
        },

        /* WATCHERS */
        watch: {

            pass: function (val, oldVal) {
                this.error = null;
            },

            confirmPass: function (val, oldVal) {
                this.error = null;
            },

        },

        /* COMPONENT METHODS  */
        methods: {
            ...mapActions(['toggleCountAction', 'setActiveTabAction', 'setScrollTabAction', 'setCollapseStateAction']),

            async linkTo(link, tab) {  

                this.setActiveTabAction(tab);
                var unique = new Date().getUTCMilliseconds();
                this.setScrollTabAction(tab + "||" + unique); //Watcher on the home page allows for direct scrolling to tab

                //Only navigate if the link has changed (We want to stay on home page for smooth scrolling)
                if (this.$route.query.page !== link)
                    this.$router.push({ name: link, params: { Section: tab } }).catch(err => { });
            },
 
             //Keeping minimize and collapse in sync for right now, but keeping as separate values in case I decide to change this
            async toggleNavMenu() {
                this.collapseNavMenu = !this.collapseNavMenu;
                this.minimizeSideBar = !this.minimizeSideBar;
                await this.setCollapseStateAction(this.collapseNavMenu);
                await this.toggleCountAction(); //Call store action
            },

            async minimizeSidebar() {
                this.minimizeSideBar = !this.minimizeSideBar;
                this.collapseNavMenu = !this.collapseNavMenu;
                await this.setCollapseStateAction(this.collapseNavMenu);
                await this.toggleCountAction(); //Call store action
            }
        },

        /* COMPUTED PROPERTIES */
        computed: {

            ...mapGetters(['currentTab', 'currentAppVersion']),

            activeTab() {
                return this.currentTab;
            },

            navMenuCssClass() { return this.collapseNavMenu ? "collapse" : null },

            desktopPaddingClass() {return this.collapseNavMenu ? "collapse-padding" : "no-collapse-padding" },

            navMenuCssTextClass() { return this.minimizeSideBar ? "sidebar-minimize-text" : null },

            sidebarCssClass() { return this.minimizeSideBar ? "minimizeSidebar" : "sidebar" },  

            sidebarButtonClass() { return this.minimizeSideBar ? ".sidebar-minimize-button" : "text-left" },  

        },
    };
</script>

<style scoped>

    .navbar-toggler {
        background-color: rgba(255, 255, 255, 0.1);
    }

    .sidebar-minimize {
        background-color: rgba(255, 255, 255, 0.1);
    }

    .sidebar-minimize-text {
        display: none;
    }

    .navMenuText {
        padding-left: 15px;
        color:white;
    }

    .collapse-padding{
        padding-top:8px;
    }

    .no-collapse-padding{
        padding-top:4px;
    }

    .sidebar-minimize-button {
        text-align: center !important;
    }

    .top-row {
        height: 3.5rem;
        background-color: rgba(0,0,0,0.4);
    }

    .top-NavBarRow {
        border-bottom-width:1px; 
        border-bottom-color:gray; 
        border-bottom-style:solid;
    }

    .navbar-brand {
        font-size: 0.95rem;
    }

    .icon-link {
        margin-left: 8px;
        width: 2rem;
        font-size: 1.1rem;
        color: white;
    }

    .btn-default {
        background-color: transparent;
        border-color: transparent;
        border-width: 0px !important;
        box-shadow: none !important;
    }

        .btn-default:hover {
            background-color: rgba(255,255,255,0.1);
        }

    /* Overwrite active background color */
    .active {
        background-color: var(--var-theme-color) !important;
    }

        .active * {
            color: black !important;
        }

    .navbar-default {
        background-color: #323548;
    }

    .nav-item {
        font-size: 0.9rem;
        padding-bottom: 0.5rem;
    }

        .nav-item:first-of-type {
            padding-top: 1rem;
        }

        .nav-item:last-of-type {
            padding-bottom: 1rem;
        }

        .nav-item ::deep a {
            color: #d7d7d7;
            border-radius: 4px;
            height: 3rem;
            display: flex;
            align-items: center;
            line-height: 3rem;
            text-align: center;
        }

     .nav-item ::deep b-button ::deep span {
        color: red !important;
    }

    /* Mobile */
    @media (max-width: 740.98px) {

        /* Collapse navbar menu in mobile mode */
        .collapse {   
            display: none;
        }

        /* Hide desktop mode */
        #divDesktop {
            display: none;
        }
    }

    /* Desktop */
    @media (min-width: 741px) {

        /* Hide mobile mode */
        #divMobile {
            display: none;
        }

        .sidebar {
            width: 250px !important;
            height: calc(100vh - 3.5rem);
            position: sticky;
            top: 0;
            flex-shrink: 0;
        }

        .minimizeSidebar {
            width: 85px !important;
            height: calc(100vh - 3.5rem);
            position: sticky;
            top: 0;
        }
    }

</style>