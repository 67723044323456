import { API } from '../shared/config';
import { HTTP_GET  } from '../shared/constants';
import { serviceHelper } from '../shared/service-helper';

const getAppVersion = async function() {
    try {
        const response = await serviceHelper.callApi(`${API}/getAppVersion`, HTTP_GET);
        return response.value;
    } catch (error) {
        return null;
    }
}

export const appService = {
    getAppVersion
};

